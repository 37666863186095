import { Mail } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC } from 'react';
import UploadFileModal from './uploadFileModal/UploadFileModal';
import ErrorHandler from './errorHandler/ErrorHandler';
import EditMailModal from './editMailModal/EditMailModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, emailActions } from 'store';
import ConfirmModal from './confirmModal/ConfirmModal';

const SendEmail: FC = () => {
    const { isUploadModalOpen } = useSelector((x: RootState) => x.email);
    const dispatch = useDispatch();
    const toggleHandler = () => dispatch(emailActions.toggleModal());
    return (
        <>
            <Button color="inherit" onClick={toggleHandler}>
                <Mail />
            </Button>
            <UploadFileModal isOpen={isUploadModalOpen} handleClose={toggleHandler} />
            <ErrorHandler />
            <EditMailModal />
            <ConfirmModal />
        </>
    );
};
export default SendEmail;
