import { Button } from '@mui/material';
import classes from './Navbar.module.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RootState, authActions } from 'store';
import { ExitToApp, KeyboardArrowDown } from '@mui/icons-material';
import { useToggle } from 'hook';
import SendEmail from 'components/sendEmail/SendEmail';
const NavBar = () => {
    const { token, user } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();
    const [isMenuOpen, menuHandler] = useToggle();
    if (!token) return <></>;

    const logoutHandler = () => {
        dispatch(authActions.logout());
        menuHandler.off();
    };

    return (
        <nav className={classes.navbar}>
            <div className={classes.logo}>Carichi in uscita</div>
            <ul className={classes['nav-links']}>
                <Button onClick={menuHandler.toggle} className={classes.hamburger} color="inherit" data-value={isMenuOpen}>
                    &#9776;
                </Button>
                <div className={classes.menu}>
                    <li>
                        <NavLink onClick={menuHandler.off} to="/" className={({ isActive }) => (isActive ? classes.active : '')}>
                            <Button color="inherit">Calendario</Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={menuHandler.off}
                            to="/agents/map"
                            className={({ isActive }) => (isActive ? classes.active : '')}
                        >
                            <Button color="inherit">Mappa</Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={menuHandler.off}
                            to="/summary"
                            className={({ isActive }) => (isActive ? classes.active : '')}
                        >
                            <Button color="inherit">Resoconto</Button>
                        </NavLink>
                    </li>
                    <li className={classes.dropdown}>
                        <Button
                            color="inherit"
                            type="button"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            IMPOSTAZIONI
                            <KeyboardArrowDown />
                        </Button>
                        <div className={classes.dropdown_content}>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/transporters"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Trasportatori
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/agents/all"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Agenti
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/materials"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Materiali
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/packages"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Confezioni
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/clients"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Clienti
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/tickets"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Carichi
                            </NavLink>
                        </div>
                    </li>
                    {user?.role === 'admin' && (
                        <li>
                            <SendEmail />
                        </li>
                    )}
                    <li>
                        <NavLink to="/login" className={({ isActive }) => (isActive ? classes.active : '')}>
                            <Button color="inherit" onClick={logoutHandler}>
                                <ExitToApp />
                            </Button>
                        </NavLink>
                    </li>
                </div>
            </ul>
        </nav>
    );
};

export default NavBar;
