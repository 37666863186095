import { useDispatch, useSelector } from 'react-redux';
import { RootState, packageActions, PackageState } from 'store';
import { useMount } from './useMount';

export const useGetMaterialPackages = () => {
    const dispatch = useDispatch();
    const { materialPackages } = useSelector<RootState>(x => x.package) as PackageState;

    useMount(() => {
        if (!materialPackages?.length) dispatch(packageActions.getMaterialPackages() as any);
    });

    return { materialPackages };
};
