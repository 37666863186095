import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';

export interface Package {
    id: string;
    name: string;
    unit: string;
    mcPerUnit: number;
    isDeleted: boolean;
}

export interface MaterialPackage {
    id: string;
    packageId: string;
    materialId: string;
    isDeleted: boolean;
}

export interface PackageState {
    packages: Package[];
    materialPackages: MaterialPackage[];
}

const name = 'package';
const initialState: PackageState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getPackages.fulfilled, (state, action) => {
            state.packages = action.payload.data;
        });
        builder.addCase(extraActions.getMaterialPackages.fulfilled, (state, action) => {
            state.materialPackages = action.payload.data;
        });
    }
});

export const packageActions = { ...slice.actions, ...extraActions };
export const packageReducer = slice.reducer;

function createInitialState() {
    return {
        packages: [],
        materialPackages: []
    };
}

function createReducers() {
    return {
        setItems,
        addPackage,
        updatePackage,
        addPackageMaterials
    };

    function setItems(state: PackageState, action: PayloadAction<{ key: keyof PackageState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addPackage(state: PackageState, action: PayloadAction<Package>) {
        state.packages = [...state.packages, action.payload];
    }

    function updatePackage(state: PackageState, action: PayloadAction<Package>) {
        const packages = [...state.packages];
        const index = packages.findIndex(pack => pack.id === action.payload.id);
        if (index >= 0) packages.splice(index, 1, action.payload);
        state.packages = packages;
    }

    function addPackageMaterials(state: PackageState, action: PayloadAction<MaterialPackage[]>) {
        state.materialPackages = [...state.materialPackages, ...action.payload];
    }
}

function createExtraReducers() {
    return {
        getPackages: getPackagesThunk(),
        getMaterialPackages: getMaterialPackagesThunk()
    };
    function getPackagesThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/packages`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
    function getMaterialPackagesThunk() {
        return createAsyncThunk(
            `${name}/materials`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/packages/materials`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}
