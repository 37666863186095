import { UploadFile, Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, emailActions } from 'store';
import classes from './UploadAttachment.module.scss';

const UploadAttachment = () => {
    const { attachment } = useSelector((x: RootState) => x.email);
    const dispatch = useDispatch();

    const onChangeFileHandler = (e: any) => {
        if (e.target.files.length === 0) return;
        dispatch(emailActions.updateAttachment(e.target.files));
        e.target.value = '';
    };

    const onDeleteFile = (file: File) => () => {
        dispatch(emailActions.removeAttachment(file));
    };

    return (
        <div className={classes.upload}>
            {attachment.length === 0 ? (
                <p>Trascina il tuo file qui oppure clicca in questa area</p>
            ) : (
                attachment.map(file => (
                    <div className={classes.fileUploaded}>
                        <UploadFile fontSize={'medium'} />
                        <p>{file.name}</p>
                        <Close onClick={onDeleteFile(file)} />
                    </div>
                ))
            )}
            <input
                type="file"
                className={attachment.length > 0 ? classes.disabledInput : ''}
                disabled={attachment.length > 0}
                multiple
                onChange={onChangeFileHandler}
            />
        </div>
    );
};

export default UploadAttachment;
