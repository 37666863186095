import Modal from 'components/modal/Modal';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, emailActions } from 'store';
import classes from './ErrorHandler.module.scss';
import { Button } from '@mui/material';
import { Email } from 'helpers';

const ErrorHandler = () => {
    const { emailToSend } = useSelector((x: RootState) => x.email);
    const dispatch = useDispatch();
    const [errorEmail, setErrorEmail] = useState<{ mail: Email; i: number; delete: boolean }[]>([]);
    const [currentUser, setCurrentUser] = useState(0);

    const {
        register,
        handleSubmit,
        formState: { isValid },
        reset
    } = useForm({
        values: {
            profession: errorEmail[currentUser]?.mail.profession || '',
            name: errorEmail[currentUser]?.mail.name || '',
            mail: errorEmail[currentUser]?.mail.mail || '',
            province: errorEmail[currentUser]?.mail.province || ''
        }
    });

    useEffect(() => {
        setErrorEmail(emailToSend.map((email, i) => ({ mail: email, i, delete: false })).filter(email => !email.mail.isValid));
    }, [emailToSend]);

    useEffect(() => {
        reset();
    }, [currentUser, errorEmail, reset]);

    const onSubmitHandler = (form: any) => {
        setErrorEmail(prev => {
            const errorEmail = [...prev];
            setCurrentUser(prev => {
                errorEmail[prev] = { mail: form, i: errorEmail[currentUser]?.i, delete: false };
                let i = prev + 1;
                while (errorEmail[i]?.delete) i++;
                if (i < errorEmail.length) {
                    return i;
                }
                dispatch(emailActions.updateEmails(errorEmail));
                return 0;
            });
            return errorEmail;
        });
    };
    const undoHandler = () => {
        setCurrentUser(prev => {
            let i = prev - 1;
            while (errorEmail[i]?.delete) i--;
            if (i >= 0) return i;
            closeModalHandler();
            return 0;
        });
    };

    const removeHandler = () => {
        setErrorEmail(prev => {
            const errorEmail = [...prev];
            setCurrentUser(prev => {
                errorEmail[prev].delete = true;
                let i = prev + 1;
                while (errorEmail[i]?.delete) i++;
                if (i < errorEmail.length) return i;
                dispatch(emailActions.updateEmails(errorEmail));
                return 0;
            });
            return errorEmail;
        });
    };

    const closeModalHandler = () => dispatch(emailActions.clearEmail());

    if (errorEmail?.length === 0) return <></>;

    return (
        <Modal isOpen={errorEmail?.length !== 0} handleClose={closeModalHandler} title="Errore nella formattazione">
            Sono presenti degli errori in alcuni degli utenti da contattare
            <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
                <label htmlFor="profession"></label>
                <input
                    className={classes.form__field}
                    placeholder="Saluti"
                    id="profession"
                    {...register('profession', { required: true })}
                />
                <label htmlFor="name"></label>
                <input className={classes.form__field} placeholder="Nome" id="name" {...register('name', { required: true })} />
                <label htmlFor="mail"></label>
                <input className={classes.form__field} placeholder="Email" id="mail" {...register('mail', { required: true })} />
                <label htmlFor="province"></label>
                <input
                    className={classes.form__field}
                    placeholder="Provincia"
                    id="province"
                    {...register('province', { required: true })}
                />
                <div className={classes.errorBtn}>
                    <Button variant="contained" type="button" onClick={undoHandler}>
                        Torna indietro
                    </Button>
                    <Button variant="contained" type="button" onClick={removeHandler}>
                        Cancella utente
                    </Button>
                    <Button variant="contained" type="submit" disabled={!isValid}>
                        Avanti
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default ErrorHandler;
