import { Button } from '@mui/material';
import Modal from 'components/modal/Modal';
import { FC } from 'react';
import classes from './DeletePackageModal.module.scss';
import axios from 'axios';
import { Package } from 'store';
import { SetState } from 'helpers';
import { env } from 'environments';

type DeletePackageModalProps = {
    selectedPackage: Package | undefined;
    setSelectedPackage: SetState<Package>;
};
const DeletePackageModal: FC<DeletePackageModalProps> = ({ selectedPackage, setSelectedPackage }) => {
    async function onDeleteHandler() {
        if (!selectedPackage) return;
        const res = await axios.delete(`${env.reactAppSocketUrl}${env.apiUrl}/packages/${selectedPackage.id}`);
        if (!res?.data) return;
        setSelectedPackage(undefined);
    }

    return (
        <Modal isOpen={!!selectedPackage} handleClose={() => setSelectedPackage(undefined)} title="Elimina materiale">
            <div className={classes.container}>
                Sei sicuro di voler eliminare la confezione selezionata?
                <Button className={classes.btn} onClick={onDeleteHandler}>
                    Elimina
                </Button>
            </div>
        </Modal>
    );
};
export default DeletePackageModal;
