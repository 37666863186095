import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { history } from 'helpers';
import { env } from 'environments';

export interface AuthState {
    user?: any;
    token?: string;
    tokenExpiration?: number;
    error: any;
}

const name = 'auth';
const initialState: AuthState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.login.fulfilled, (state, action) => login(state, action));
        builder.addDefaultCase((state, action) => {
            if (action.error?.message === 'invalid-login') {
                state.error = { message: 'Email e/o password non validi' };
                return;
            }
            state.error = action.error;
        });
    }
});

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createInitialState() {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const tokenExpiration = +(localStorage.getItem('tokenExpiration') ?? 0);

    return {
        token,
        tokenExpiration: tokenExpiration,
        error: undefined,
        user: user ? JSON.parse(user) : undefined
    } as AuthState;
}

function createReducers() {
    return { logout };

    function logout(state: AuthState) {
        state.user = undefined;
        state.token = undefined;
        state.tokenExpiration = undefined;
        localStorage.clear();
        history.navigate('/');
    }
}

interface LoginParams {
    email: string;
    password: string;
}

function createExtraActions() {
    return {
        login: loginThunk()
    };

    function loginThunk() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }: LoginParams) =>
                await axios.post(`${env.reactAppSocketUrl}${env.apiUrl}/auth`, { email, password }).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}

function login(state: AuthState, action: PayloadAction<any>) {
    const payload = action.payload.data;
    state.user = payload.user;
    state.token = payload.token;
    state.tokenExpiration = payload.exp;
    localStorage.setItem('user', JSON.stringify(payload.user));
    localStorage.setItem('tokenExpiration', payload.exp.toString());
    localStorage.setItem('token', payload.token);
    const { from } = (history.location.state as any) || { from: { pathname: '/' } };
    history.navigate(from);
}
