import { useDispatch, useSelector } from 'react-redux';
import { RootState, TicketState, ticketActions } from 'store';
import { useMount } from './useMount';

export const useGetTickets = () => {
    const dispatch = useDispatch();
    const { tickets } = useSelector<RootState>(x => x.ticket) as TicketState;
    const pureTickets = tickets.map(ticket => ticket.resource);

    useMount(() => {
        if (!tickets?.length) dispatch(ticketActions.getTickets() as any);
    });

    return { tickets, pureTickets };
};
