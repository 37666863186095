import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Package } from 'store';
import { FC } from 'react';

import classes from './EditPackageForm.module.scss';
import { SetState } from 'helpers';
import { Button } from '@mui/material';
import { env } from 'environments';

type CreateClientFormProps = {
    selectedPackage: Package;
    setSelectedPackage: SetState<Package>;
};

const CreateClientForm: FC<CreateClientFormProps> = ({ selectedPackage, setSelectedPackage }) => {
    const {
        register,
        handleSubmit,
        formState: { isValid }
    } = useForm({
        values: {
            name: selectedPackage?.name,
            unit: selectedPackage.unit,
            mcPerUnit: selectedPackage.mcPerUnit
        },
        defaultValues: {
            name: selectedPackage?.name,
            unit: selectedPackage.unit,
            mcPerUnit: selectedPackage.mcPerUnit
        }
    });

    async function onSubmitHandler(info: any) {
        const res = await axios.put(`${env.reactAppSocketUrl}${env.apiUrl}/packages/` + selectedPackage?.id, info);
        if (!res?.data) return;
        setSelectedPackage(undefined);
    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
            <label htmlFor="name"></label>
            <input className={classes.form__field} placeholder="Nome" id="name" {...register('name', { required: true })} />
            <label htmlFor="unit"></label>
            <input className={classes.form__field} placeholder="Unità" id="unit" {...register('unit', { required: true })} />
            <label htmlFor="mcPerUnit"></label>
            <input
                className={classes.form__field}
                placeholder="Mc ogni unità"
                id="mcPerUnit"
                type="number"
                step={0.01}
                {...register('mcPerUnit', { required: true })}
            />
            <Button variant="contained" type="submit" disabled={!isValid}>
                Salva confezione
            </Button>
        </form>
    );
};

export default CreateClientForm;
