import { configureStore } from '@reduxjs/toolkit';
import { ticketReducer } from './tickets.slice';
import { agentReducer } from './agents.slice';
import { transporterReducer } from './transporters.slice';
import { materialReducer } from './materials.slice';
import { authReducer } from './auth.slice';
import { socketReducer } from './socketIO.slice';
import { clientReducer } from './clients.slice';
import { emailReducer } from './email.slice';
import { packageReducer } from './packages.slice';

export * from './tickets.slice';
export * from './agents.slice';
export * from './transporters.slice';
export * from './materials.slice';
export * from './clients.slice';
export * from './auth.slice';
export * from './socketIO.slice';
export * from './email.slice';
export * from './packages.slice';

export const store = configureStore({
    reducer: {
        ticket: ticketReducer,
        agent: agentReducer,
        transporter: transporterReducer,
        material: materialReducer,
        auth: authReducer,
        client: clientReducer,
        email: emailReducer,
        socket: socketReducer,
        package: packageReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
