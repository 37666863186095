import Modal from 'components/modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, emailActions } from 'store';

const ConfirmModal = () => {
    const { isSendCompleted } = useSelector((x: RootState) => x.email);
    const dispatch = useDispatch();
    const toggleHandler = () => dispatch(emailActions.toggleConfirm());
    return <Modal isOpen={isSendCompleted} handleClose={toggleHandler} title="Invio delle mail iniziato"></Modal>;
};

export default ConfirmModal;
