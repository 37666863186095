import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import classes from './Modal.module.scss';
import { Close } from '@mui/icons-material';

type ModalProps = FC<
    PropsWithChildren<{
        hasHeader?: boolean;
        customClasses?: string;
        handleClose: (...data: any) => any;
        isOpen: boolean;
        title?: string;
        icon?: any;
    }>
>;

const Modal: ModalProps = ({ icon, children, isOpen, handleClose, title, customClasses, hasHeader = true }) => {
    if (!isOpen) return <></>;

    return createPortal(
        <div className={classes.backdrop}>
            <div className={hasHeader ? classes.modal : classes.notifyModal}>
                {hasHeader ? (
                    <div className={icon ? classes.iconHeader : classes.header}>
                        <div className={classes.iconTitle}>
                            {icon && <div className={classes.icon}>{icon}</div>}
                            <h3>{title}</h3>
                        </div>
                        <div onClick={handleClose} className={classes.close}>
                            <Close />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className={`${classes.scrollable} ${customClasses}`}>{children}</div>
            </div>
        </div>,
        document.getElementById('modal-root') as Element
    );
};

export default Modal;
