import { useGetData } from 'hook';
import { useMemo } from 'react';

export const useGetTicketSelectOption = () => {
    const { agents, materials, transporters, clients, packages } = useGetData();

    const agentOptions = useMemo(
        () =>
            agents
                .filter(({ isDeleted }) => !isDeleted)
                .map(agent => ({ value: agent.id, label: `${agent.firstName} ${agent.lastName}` })),
        [agents]
    );
    const transporterOptions = useMemo(
        () =>
            transporters
                .filter(({ isDeleted }) => !isDeleted)
                .map(transporter => ({
                    value: transporter.id,
                    label: `${transporter.firstName} ${transporter.lastName}`
                })),
        [transporters]
    );
    const materialOptions = useMemo(
        () => materials.filter(({ isDeleted }) => !isDeleted).map(({ name, id }) => ({ value: id, label: name })),
        [materials]
    );
    const packageOptions = useMemo(
        () => packages.filter(({ isDeleted }) => !isDeleted).map(({ name, id }) => ({ value: id, label: name })),
        [packages]
    );
    const clientOptions = useMemo(
        () => clients.filter(({ isDeleted }) => !isDeleted).map(client => ({ value: client.id, label: client.name })),
        [clients]
    );
    return { agentOptions, transporterOptions, materialOptions, clientOptions, packageOptions };
};
