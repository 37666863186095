import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';

export interface Agent {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    cel: string;
    bossProvince?: any;
    agentProvince?: any;
    isDeleted: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface AgentState {
    agents: Agent[];
    selectedAgent?: Agent;
    isSelectedBoss?: boolean;
    editingAgent: boolean;
    agentProvinces?: any[];
    provincesInfo?: any[];
}

const name = 'agent';
const initialState: AgentState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getAgents.fulfilled, (state, action) => {
            state.agents = action.payload.data;
        });
    }
});

export const agentActions = { ...slice.actions, ...extraActions };
export const agentReducer = slice.reducer;

function createInitialState() {
    return {
        agents: [],
        editingAgent: false
    };
}

function createReducers() {
    return {
        setItems,
        addAgent,
        deleteAgent,
        updateAgent
    };

    function setItems(state: AgentState, action: PayloadAction<{ key: keyof AgentState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addAgent(state: AgentState, action: PayloadAction<Agent>) {
        state.agents = [...state.agents, action.payload];
    }

    function deleteAgent(state: AgentState, action: PayloadAction<string>) {
        const agents = [...state.agents];
        const index = agents.findIndex(agent => agent.id === action.payload);
        if (index >= 0) agents.splice(index, 1);
        state.agents = agents;
    }

    function updateAgent(state: AgentState, action: PayloadAction<Agent>) {
        const agents = [...state.agents];
        const index = agents.findIndex(agent => agent.id === action.payload.id);
        if (index >= 0) agents.splice(index, 1, action.payload);
        state.agents = agents;
    }
}

function createExtraReducers() {
    return {
        getAgents: getAgentsThunk()
    };
    function getAgentsThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/agents`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}
