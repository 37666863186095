import { Button, Step, StepLabel, Stepper } from '@mui/material';
import Modal from 'components/modal/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, emailActions } from 'store';
import classes from './EditMailModal.module.scss';
import EditObject from './editObject/EditObject';
import EditBody from './editBody/EditBody';
import EditFooter from './editFooter/EditFooter';
import EmailSummary from './emailSummary/EmailSummary';
import { Email } from 'helpers';
import UploadAttachment from './uploadAttachment/UploadAttachment';

const EditMailModal = () => {
    const { emailToSend, object, body, footer, footerType, provinceFooter, attachment } = useSelector((x: RootState) => x.email);
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return dispatch(emailActions.clearEmail());
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleFinish = () => {
        const mail: { [x: string]: any } = {
            object,
            attachment,
            bodies: []
        };
        function formatEmail(data: Email) {
            let currentBody = `<div style="margin-bottom: 0.5rem">${data.profession}${data.name},</div>`;
            currentBody = currentBody.concat(body);
            currentBody = currentBody.concat(
                footerType === 'all' ? footer : provinceFooter[data.agent ? `${data.province} ${data.agent}` : data.province]
            );
            return { body: currentBody, ...data };
        }
        mail.bodies = emailToSend.map(mail => formatEmail(mail));
        dispatch(emailActions.postSend(mail) as any);
        setActiveStep(0);
    };

    const handleClose = () => {
        dispatch(emailActions.resetStore());
        setActiveStep(0);
    };

    return (
        <Modal
            isOpen={emailToSend.length > 0 && !emailToSend.some(e => !e.isValid)}
            handleClose={handleClose}
            title="Modifica l'email"
        >
            <div className={classes.container}>
                <Stepper activeStep={activeStep}>
                    <Step>
                        <StepLabel>Oggetto dell'email</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Corpo</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Pié di pagina</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Allegati</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Revisione</StepLabel>
                    </Step>
                </Stepper>
                {activeStep === 0 && <EditObject />}
                {activeStep === 1 && <EditBody />}
                {activeStep === 2 && <EditFooter />}
                {activeStep === 3 && <UploadAttachment />}
                {activeStep === 4 && <EmailSummary />}
                <div className={classes.footer}>
                    <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                        indietro
                    </Button>
                    <Button onClick={activeStep === 4 ? handleFinish : handleNext}>
                        {activeStep === 4 ? 'Invia' : 'Avanti'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditMailModal;
