import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import classes from './EditFooter.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, emailActions } from 'store';
import { MenuItem, Radio, Select } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

const EditFooter = () => {
    const { footer, footerType, emailToSend, provinceFooter } = useSelector((x: RootState) => x.email);
    const [province, setProvince] = useState<string[]>([]);
    const [selectedProvince, setSelectedProvince] = useState('');
    const dispatch = useDispatch();
    const ref = useRef(null);

    const onKeyDownProvinceEditor = () => {
        if (!ref.current) return;
        dispatch(emailActions.updateProvinceFooter({ footer: (ref.current as any).value, province: selectedProvince }));
    };

    const onChangeEditor = useCallback(
        (footer: any) => {
            dispatch(emailActions.updateFooter(footer));
        },
        [dispatch]
    );

    const onChangeRadio = useCallback(
        (e: any) => {
            dispatch(emailActions.updateFooterType(e.target.value));
        },
        [dispatch]
    );

    const onChangeSelect = useCallback((e: any) => {
        setSelectedProvince(e.target.value);
    }, []);

    useEffect(() => {
        setProvince(() => {
            const province = [
                ...new Set(emailToSend.map(mail => (mail.agent ? `${mail.province} ${mail.agent}` : mail.province)))
            ];
            setSelectedProvince(province[0]);
            return province;
        });
    }, [emailToSend]);

    return (
        <div className={classes.container}>
            <Radio checked={footerType === 'all'} onChange={onChangeRadio} value="all" id="all" name="radio-buttons" />
            <label htmlFor="all">Applica a tutti</label>
            <Radio
                checked={footerType === 'province'}
                onChange={onChangeRadio}
                value="province"
                id="province"
                name="radio-buttons"
            />
            <label htmlFor="province">Dividi in province</label>
            {footerType === 'all' && (
                <ReactQuill className={classes.editor} theme="snow" value={footer} onChange={onChangeEditor} />
            )}
            {footerType === 'province' && (
                <>
                    <Select className={classes.select} value={selectedProvince} onChange={onChangeSelect} labelId="select-label">
                        {province.map(p => (
                            <MenuItem key={p} value={p}>
                                {p}
                            </MenuItem>
                        ))}
                    </Select>
                    <ReactQuill
                        ref={ref}
                        className={classes.editor}
                        theme="snow"
                        onKeyUp={onKeyDownProvinceEditor}
                        value={provinceFooter[selectedProvince]}
                    />
                </>
            )}
        </div>
    );
};

export default EditFooter;
