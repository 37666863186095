import Modal from 'components/modal/Modal';
import classes from './UploadFileModal.module.scss';
import { FC } from 'react';
import { Close, UploadFile } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, emailActions } from 'store';

type UploadFileModalProps = FC<{ isOpen: boolean; handleClose: () => void }>;
const UploadFileModal: UploadFileModalProps = ({ handleClose, isOpen }) => {
    const dispatch = useDispatch();
    const { emailToSend, error, file } = useSelector((x: RootState) => x.email);

    const uploadFile = async () => {
        if (!file) return;
        dispatch(emailActions.postFile(file) as any);
    };

    const onChangeFileHandler = (e: any) => {
        dispatch(emailActions.setFile(e.target.files && e.target.files[0]));
        e.target.value = '';
    };

    return (
        <Modal isOpen={isOpen && emailToSend.length === 0} handleClose={handleClose} title="Invio mail">
            Carica un file excel (Professione, Nome, Cognome, email, provincia)
            <div className={classes.upload}>
                {!file ? (
                    <p>Clicca in questa area per caricare il file</p>
                ) : (
                    <div className={classes.fileUploaded}>
                        <UploadFile fontSize={'large'} />
                        <p>{file.name}</p>
                        <Close onClick={() => dispatch(emailActions.setFile(undefined))} />
                    </div>
                )}

                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className={!!file ? classes.disabledInput : ''}
                    disabled={!!file}
                    onChange={onChangeFileHandler}
                />
            </div>
            <button className={classes.uploadBtn} type="submit" onClick={uploadFile} disabled={!file}>
                Invia
            </button>
            {error && (
                <p className={classes.error}>
                    {error},<br /> controlla il file e riprova l'upload
                </p>
            )}
        </Modal>
    );
};

export default UploadFileModal;
