import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';
import { Event as BigEvent } from 'react-big-calendar';

export enum TicketStatus {
    paid = 'paid',
    unpaid = 'unpaid',
    handled = 'handled'
}

export interface Event extends BigEvent {
    resource: Ticket;
}

export interface TicketMaterial {
    materialId: string;
    packageId: string;
    ticketId: string;
    quantity: number;
}

export interface MaterialRow {
    TicketMaterials: TicketMaterial;
    name: string;
}

export interface TicketRow {
    id: string;
    materials: MaterialRow[];
    note: string;
    date: number;
    status: TicketStatus;
    clientId: string;
    transporterId: string;
    agentId: string;
    destination: string;
}

export interface Ticket {
    id: string;
    materials: TicketMaterial[];
    note: string;
    date: number;
    status: TicketStatus;
    clientId: string;
    transporterId: string;
    agentId: string;
    destination: string;
}

export interface TicketState {
    tickets: Event[];
}

const name = 'ticket';
const initialState: TicketState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getTickets.fulfilled, (state, action) => {
            const events: Event[] = action.payload.data.map(event => formatEvent(event, true));
            state.tickets = events;
        });
    }
});

export const ticketActions = { ...slice.actions, ...extraActions };
export const ticketReducer = slice.reducer;

function createInitialState() {
    return {
        tickets: [],
        clients: []
    };
}

function createReducers() {
    return {
        setItems,
        addTicket,
        updateTicket,
        deleteTicket
    };

    function setItems(state: TicketState, action: PayloadAction<{ key: keyof TicketState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addTicket(state: TicketState, action: PayloadAction<TicketRow>) {
        const ticket = formatEvent(action.payload, true);
        const tickets = [...state.tickets, ticket];
        state.tickets = tickets;
    }

    function updateTicket(state: TicketState, action: PayloadAction<{ ticket: TicketRow; shouldOffset: boolean }>) {
        const ticket = formatEvent(action.payload.ticket, action.payload.shouldOffset);
        const tickets = [...state.tickets];
        const index = tickets.findIndex(tick => tick.resource.id === action.payload.ticket.id);
        if (index >= 0) tickets.splice(index, 1, ticket);
        state.tickets = tickets;
    }

    function deleteTicket(state: TicketState, action: PayloadAction<string>) {
        const tickets = [...state.tickets];
        const index = tickets.findIndex(ticket => ticket.resource.id === action.payload);
        if (index >= 0) tickets.splice(index, 1);
        state.tickets = tickets;
    }
}

function createExtraReducers() {
    return {
        getTickets: getTicketsThunk()
    };
    function getTicketsThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get<TicketRow[]>(`${env.reactAppSocketUrl}${env.apiUrl}/tickets`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}

function formatEvent(event: TicketRow, shouldOffset: boolean) {
    const materials = event.materials.map(
        (mat: any) =>
            ({
                materialId: mat.materialId,
                packageId: mat.packageId,
                quantity: mat.TicketMaterials.quantity,
                ticketId: mat.TicketMaterials.ticketId
            } as TicketMaterial)
    );
    const ticket: Ticket = { ...event, materials };

    const date = shouldOffset
        ? new Date(ticket.date + new Date(ticket.date).getTimezoneOffset() * 1000 * 60)
        : new Date(ticket.date);

    const endDate = new Date(date);
    endDate.setHours(12);
    return {
        title: ticket.materials,
        allDay: true,
        start: date,
        end: endDate,
        resource: ticket
    } as Event;
}
