import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';
import { Email } from 'helpers';

export interface EmailState {
    file: File | undefined;
    emailToSend: Email[];
    object: string;
    body: string;
    footer: string;
    provinceFooter: { [x: string]: string };
    footerType: string;
    attachment: File[];
    isUploadModalOpen: boolean;
    isSendCompleted: boolean;
    error: string;
}

const name = 'email';
const initialState: EmailState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.postFile.fulfilled, (state, action) => {
            if (!action.payload) return;
            state.emailToSend = action.payload.data;
        });
        builder.addCase(extraActions.postFile.rejected, (state, action) => {
            if (!action.error) return;
            state.error = action.error.message ?? '';
        });
        builder.addCase(extraActions.postSend.fulfilled, state => {
            state = createInitialState();
            state.isSendCompleted = true;
            return state;
        });
    }
});

export const emailActions = { ...slice.actions, ...extraActions };
export const emailReducer = slice.reducer;

function createInitialState() {
    return {
        file: undefined,
        emailToSend: [],
        object: '',
        body: '',
        footer: '',
        provinceFooter: {},
        footerType: 'all',
        attachment: [],
        isUploadModalOpen: false,
        isSendCompleted: false,
        error: ''
    };
}

function createReducers() {
    return {
        resetStore,
        setFile,
        toggleModal,
        toggleConfirm,
        clearEmail,
        updateEmails,
        updateObject,
        updateBody,
        updateFooter,
        updateAttachment,
        updateFooterType,
        removeAttachment,
        updateProvinceFooter
    };

    function resetStore(state: EmailState) {
        state = createInitialState();
        return state;
    }

    function setFile(state: EmailState, action: PayloadAction<File | undefined>) {
        state.file = action.payload;
    }

    function toggleModal(state: EmailState) {
        state.isUploadModalOpen = !state.isUploadModalOpen;
    }

    function toggleConfirm(state: EmailState) {
        state.isSendCompleted = !state.isSendCompleted;
    }

    function clearEmail(state: EmailState) {
        state.emailToSend = [];
    }

    function updateObject(state: EmailState, action: PayloadAction<string>) {
        state.object = action.payload;
    }

    function updateBody(state: EmailState, action: PayloadAction<string>) {
        state.body = action.payload;
    }

    function updateFooter(state: EmailState, action: PayloadAction<string>) {
        state.footer = action.payload;
    }

    function updateFooterType(state: EmailState, action: PayloadAction<string>) {
        state.footerType = action.payload;
    }

    function updateAttachment(state: EmailState, action: PayloadAction<FileList>) {
        const length = action.payload.length;
        state.attachment = [];
        for (let i = 0; i < length; i++) {
            state.attachment.push(action.payload[i]);
        }
    }

    function removeAttachment(state: EmailState, action: PayloadAction<File>) {
        state.attachment = state.attachment.filter(file => file.name !== action.payload.name);
    }

    function updateProvinceFooter(state: EmailState, action: PayloadAction<{ footer: string; province: string }>) {
        const { footer, province } = action.payload;
        state.provinceFooter[province] = footer;
    }

    function updateEmails(state: EmailState, action: PayloadAction<{ mail: Email; i: number; delete: boolean }[]>) {
        const emailToSend = [...state.emailToSend];
        action.payload.forEach(e => {
            emailToSend[e.i] = { ...e.mail, isValid: true };
        });
        state.emailToSend = emailToSend.filter((_, i) => !action.payload.some(m => m.i === i && m.delete));
    }
}

function createExtraReducers() {
    return {
        postFile: postFileThunk(),
        postSend: postSendThunk()
    };

    function postFileThunk() {
        return createAsyncThunk(`postFile`, async (file: File) => {
            if (!file) return;
            const form = new FormData();
            form.append('file', file, file?.name);
            return await axios.post<Email[]>(`${env.reactAppSocketUrl}${env.apiUrl}/mails/file-upload`, form).catch(e => {
                throw new Error(e.response.data?.message);
            });
        });
    }

    function postSendThunk() {
        return createAsyncThunk(`postSend`, async (mails: any) => {
            if (!mails) return;
            const form = new FormData();
            mails.attachment.forEach((file: File) => form.append('files', file, file?.name));
            form.append('object', JSON.stringify(mails.object));
            form.append('bodies', JSON.stringify(mails.bodies));
            return await axios.post<Email[]>(`${env.reactAppSocketUrl}${env.apiUrl}/mails/send-email`, form);
        });
    }
}
