import Modal from 'components/modal/Modal';
import { FC } from 'react';
import CreatePackageForm from './createPackageForm/CreatePackageForm';

type CreatePackageModalProps = { isOpen: boolean; toggle: () => void };

const CreatePackageModal: FC<CreatePackageModalProps> = ({ isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} handleClose={toggle} title="Aggiungi una nuova confezione">
            <CreatePackageForm toggle={toggle} />
        </Modal>
    );
};
export default CreatePackageModal;
