import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import classes from './EditBody.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, emailActions } from 'store';

const EditBody = () => {
    const { body } = useSelector((x: RootState) => x.email);
    const dispatch = useDispatch();

    const onChangeHandler = (body: any) => {
        dispatch(emailActions.updateBody(body));
    };

    return (
        <div className={classes.container}>
            <ReactQuill className={classes.editor} theme="snow" value={body} onChange={onChangeHandler} />
        </div>
    );
};

export default EditBody;
