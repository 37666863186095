import Modal from 'components/modal/Modal';
import { FC } from 'react';
import EditPackageForm from './editPackageForm/EditPackageForm';
import { Package } from 'store';
import { SetState } from 'helpers';

type EditPackageModalProps = { selectedPackage: Package | undefined; setSelectedPackage: SetState<Package> };

const EditPackageModal: FC<EditPackageModalProps> = ({ selectedPackage, setSelectedPackage }) => {
    return (
        <Modal isOpen={!!selectedPackage} handleClose={() => setSelectedPackage(undefined)} title="Modifica Confezione">
            <EditPackageForm selectedPackage={selectedPackage!} setSelectedPackage={setSelectedPackage} />
        </Modal>
    );
};
export default EditPackageModal;
