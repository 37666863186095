import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ResponseTopics, socketActions } from 'store';

export const useSocketListener = (topics: ResponseTopics[], listener: (...args: any[]) => void) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(socketActions.createListeners({ topics, listener }));
        return () => {
            dispatch(socketActions.removeListener(topics));
        };
    }, [listener]);
};
