import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';

export interface Material {
    id: string;
    name: string;
    isDeleted: boolean;
}

export interface MaterialState {
    materials: Material[];
}

const name = 'material';
const initialState: MaterialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getMaterials.fulfilled, (state, action) => {
            state.materials = action.payload.data;
        });
    }
});

export const materialActions = { ...slice.actions, ...extraActions };
export const materialReducer = slice.reducer;

function createInitialState() {
    return {
        materials: []
    };
}

function createReducers() {
    return {
        setItems,
        addMaterial,
        updateMaterial
    };

    function setItems(state: MaterialState, action: PayloadAction<{ key: keyof MaterialState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addMaterial(state: MaterialState, action: PayloadAction<Material>) {
        state.materials = [...state.materials, action.payload];
    }
    function updateMaterial(state: MaterialState, action: PayloadAction<Material>) {
        const materials = [...state.materials];
        const index = materials.findIndex(material => material.id === action.payload.id);
        if (index >= 0) materials.splice(index, 1, action.payload);
        state.materials = materials;
    }
}

function createExtraReducers() {
    return {
        getMaterials: getMaterialsThunk()
    };
    function getMaterialsThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/materials`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}
