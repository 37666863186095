import React, { Suspense, useCallback, useEffect } from 'react';
import Routing from './Routing';
import './styles/main.scss';
import NavBar from 'views/navbar/Navbar';
import { history } from 'helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    AuthState,
    RootState,
    agentActions,
    clientActions,
    materialActions,
    packageActions,
    socketActions,
    ticketActions,
    transporterActions
} from 'store';
import { useSocketListener } from 'hook';
import axios from 'axios';
import { env } from 'environments';

function App() {
    history.location = useLocation();
    history.navigate = useNavigate();

    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();

    const updateHandler = useCallback(
        (event: any) => {
            const updateAction = {
                agent: (agent: any) => dispatch(agentActions.updateAgent(agent)),
                ticket: (ticket: any) => dispatch(ticketActions.updateTicket({ ticket, shouldOffset: false })),
                province: async (agentId: any) => {
                    const { data } = await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/provinces/info`);
                    dispatch(agentActions.setItems({ key: 'provincesInfo', value: data }));
                    const { data: agent } = await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/agents/${agentId}/provinces`);
                    dispatch(agentActions.setItems({ key: 'selectedAgent', value: agent }));
                    console.log('update');
                },
                package: (pack: any) => dispatch(packageActions.updatePackage(pack)),
                material: (material: any) => {
                    dispatch(packageActions.getMaterialPackages() as any);
                    dispatch(materialActions.updateMaterial(material));
                },
                transporter: (transporter: any) => dispatch(transporterActions.updateTransporter(transporter)),
                client: (client: any) => dispatch(clientActions.updateClient(client))
            };
            updateAction[event.payload.key as keyof typeof updateAction](event.payload.value);
        },
        [dispatch]
    );

    const createHandler = useCallback(
        (event: any) => {
            const createAction = {
                agent: (agent: any) => dispatch(agentActions.addAgent(agent)),
                package: (pack: any) => dispatch(packageActions.addPackage(pack)),
                material: ({ material, materialPackages }: any) => {
                    dispatch(packageActions.addPackageMaterials(materialPackages));
                    dispatch(materialActions.addMaterial(material));
                },
                ticket: (ticket: any) => {
                    return dispatch(ticketActions.addTicket(ticket));
                },
                transporter: (transporter: any) => dispatch(transporterActions.addTransporter(transporter)),
                client: (client: any) => dispatch(clientActions.addClient(client))
            };
            createAction[event.payload.key as keyof typeof createAction](event.payload.value);
        },
        [dispatch]
    );

    const deleteHandler = useCallback(
        (event: any) => {
            const deleteAction = {
                material: (material: any) => dispatch(materialActions.updateMaterial(material)),
                ticket: (ticket: any) => dispatch(ticketActions.deleteTicket(ticket))
            };
            deleteAction[event.payload.key as keyof typeof deleteAction](event.payload.value);
        },
        [dispatch]
    );

    useSocketListener(['create'], createHandler);
    useSocketListener(['update'], updateHandler);
    useSocketListener(['delete'], deleteHandler);

    useEffect(() => {
        dispatch(
            socketActions.login({
                email: user?.email as string
            })
        );
    }, [dispatch, user?.email]);

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <NavBar />
            <Routing />
        </Suspense>
    );
}

export default App;
