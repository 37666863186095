import socketio, { Socket } from 'socket.io-client';
import { env } from 'environments';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialSocketState {
    socket: Socket | any;
}

export interface SocketState extends InitialSocketState {
    socket: Socket;
}

export type MessageTopics = 'login' | 'sendEmail' | 'logout';
export type ResponseTopics = 'client' | 'create' | 'update' | 'delete';

const name = 'socketIO';
const initialState: InitialSocketState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const socketActions = { ...slice.actions };
export const socketReducer = slice.reducer;

function createInitialState() {
    return {
        socket: socketio(env.reactAppSocketUrl)
    } as InitialSocketState;
}

function createReducers() {
    return {
        login,
        sendMessage,
        createListeners,
        removeListener
    };

    function login(state: SocketState, action: PayloadAction<{ email: string }>) {
        const { email } = action.payload;
        state.socket.emit('msg', { topic: 'login', payload: { email } });
    }

    function sendMessage(state: SocketState, action: PayloadAction<{ topic: MessageTopics; payload: any }>) {
        state.socket.emit('msg', action.payload);
    }

    function createListeners(
        state: SocketState,
        action: PayloadAction<{
            topics: ResponseTopics[];
            listener: (...args: any[]) => void;
        }>
    ) {
        action.payload.topics.forEach(topic => state.socket?.on(topic, action.payload.listener));
    }

    function removeListener(state: SocketState, action: PayloadAction<ResponseTopics[]>) {
        action.payload.forEach(listener => {
            state.socket?.removeListener(listener);
        });
    }
}
