import { useDispatch, useSelector } from 'react-redux';
import { RootState, TransporterState, transporterActions } from 'store';
import { useMount } from './useMount';

export const useGetTransporters = () => {
    const dispatch = useDispatch();
    const { transporters } = useSelector<RootState>(x => x.transporter) as TransporterState;

    useMount(() => {
        if (!transporters?.length) dispatch(transporterActions.getTransporters() as any);
    });

    return { transporters };
};
