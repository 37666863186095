import { useGetPackages, useToggle } from 'hook';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';
import classes from './Packages.module.scss';
import { Add, Delete, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import CreatePackageModal from './createPackageModal/CreatePackageModal';
import { FC, useMemo, useState } from 'react';
import DeletePackageModal from './deletePackageModal/DeletePackageModal';
import { Package, RootState } from 'store';
import EditPackageModal from './editPackageModal/EditPackageModal';
import { useSelector } from 'react-redux';

const Packages: FC = () => {
    const [isCreateModalOpen, createModalHandler] = useToggle();
    const [deleteSelectedPackage, setDeleteSelectedPackage] = useState<Package | undefined>(undefined);
    const [editSelectedPackage, setEditSelectedPackage] = useState<Package | undefined>(undefined);
    const { packages } = useGetPackages();
    const { user } = useSelector((x: RootState) => x.auth);

    const activePackages = useMemo(() => packages.filter(p => !p.isDeleted), [packages]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Nome'
        },
        {
            field: 'unit',
            flex: 1,
            headerName: 'Unità'
        },
        {
            field: 'mcPerUnit',
            flex: 1,
            headerName: 'Moltiplicatore'
        },
        {
            field: 'id',
            width: 142,
            filterable: false,
            hideable: false,
            sortable: false,
            renderHeader() {
                if (user?.role !== 'admin') return <></>;
                return (
                    <div className={classes.buttons}>
                        <Button onClick={createModalHandler.toggle}>
                            <Add />
                        </Button>
                    </div>
                );
            },
            renderCell(params) {
                if (user?.role !== 'admin') return <></>;
                return (
                    <div className={classes.buttons}>
                        <Button onClick={() => setEditSelectedPackage(params.row)}>
                            <Edit />
                        </Button>
                        <Button onClick={() => setDeleteSelectedPackage(params.row)}>
                            <Delete />
                        </Button>
                    </div>
                );
            }
        }
    ];

    return (
        <div className={classes.container}>
            <DataGrid
                rows={activePackages}
                columns={columns}
                autoPageSize={true}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            />
            <CreatePackageModal isOpen={isCreateModalOpen} toggle={createModalHandler.toggle} />
            <DeletePackageModal selectedPackage={deleteSelectedPackage} setSelectedPackage={setDeleteSelectedPackage} />
            <EditPackageModal selectedPackage={editSelectedPackage} setSelectedPackage={setEditSelectedPackage} />
        </div>
    );
};

export default Packages;
