import { useDispatch, useSelector } from 'react-redux';
import { RootState, AgentState, agentActions } from 'store';
import { useMount } from './useMount';

export const useGetAgents = () => {
    const dispatch = useDispatch();
    const { agents } = useSelector<RootState>(x => x.agent) as AgentState;

    useMount(() => {
        if (!agents?.length) dispatch(agentActions.getAgents() as any);
    });

    return { agents };
};
