import { useDispatch, useSelector } from 'react-redux';
import { RootState, materialActions, MaterialState } from 'store';
import { useMount } from './useMount';

export const useGetMaterials = () => {
    const dispatch = useDispatch();
    const { materials } = useSelector<RootState>(x => x.material) as MaterialState;

    useMount(() => {
        if (!materials?.length) dispatch(materialActions.getMaterials() as any);
    });

    return { materials };
};
