import { useDispatch, useSelector } from 'react-redux';
import { RootState, clientActions } from 'store';
import { useMount } from './useMount';

export const useGetClients = () => {
    const dispatch = useDispatch();
    const { clients } = useSelector((x: RootState) => x.client);

    useMount(() => {
        if (!clients?.length) dispatch(clientActions.getClients() as any);
    });

    return { clients };
};
