import { useSelector } from 'react-redux';
import { RootState } from 'store';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import classes from './EmailSummary.module.scss';

const EmailSummary = () => {
    const { body, object, footer, footerType, emailToSend, provinceFooter } = useSelector((x: RootState) => x.email);

    return (
        <Carousel className={classes.carousel} showThumbs={false}>
            {emailToSend.map(email => (
                <div className={classes.slide}>
                    <div className={classes.object}>Oggetto: {object}</div>
                    <div>
                        {email.profession}
                        {email.name},
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                    {footerType === 'all' && <div className={classes.footer} dangerouslySetInnerHTML={{ __html: footer }} />}
                    {footerType === 'province' && (
                        <div
                            className={classes.footer}
                            dangerouslySetInnerHTML={{
                                __html: provinceFooter[email.agent ? `${email.province} ${email.agent}` : email.province]
                            }}
                        />
                    )}
                </div>
            ))}
        </Carousel>
    );
};
export default EmailSummary;
