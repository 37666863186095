import { useGetAgents, useGetMaterials, useGetTransporters, useGetTickets, useGetClients } from 'hook';
import { useGetPackages } from './useGetPackages';

export const useGetData = () => {
    const { agents } = useGetAgents();
    const { materials } = useGetMaterials();
    const { transporters } = useGetTransporters();
    const { tickets, pureTickets } = useGetTickets();
    const { clients } = useGetClients();
    const { packages } = useGetPackages();

    return { agents, materials, transporters, tickets, pureTickets, clients, packages };
};
