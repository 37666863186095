import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FC } from 'react';

import classes from './CreatePackageForm.module.scss';
import { Button } from '@mui/material';
import { env } from 'environments';

type CreatePackageFormProps = {
    toggle: () => void;
};

const CreatePackageForm: FC<CreatePackageFormProps> = ({ toggle }) => {
    const {
        register,
        handleSubmit,
        formState: { isValid }
    } = useForm({
        values: {
            name: '',
            unit: '',
            mcPerUnit: ''
        }
    });

    async function onSubmitHandler(info: any) {
        const res = await axios.post(`${env.reactAppSocketUrl}${env.apiUrl}/packages`, info);
        if (!res?.data) return;
        toggle();
    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
            <label htmlFor="name"></label>
            <input className={classes.form__field} placeholder="Nome" id="name" {...register('name', { required: true })} />
            <label htmlFor="unit"></label>
            <input
                className={classes.form__field}
                placeholder="Unità"
                type="text"
                id="unit"
                {...register('unit', { required: true })}
            />
            <label htmlFor="mcPerUnit"></label>
            <input
                className={classes.form__field}
                placeholder="Mc ogni unità"
                type="number"
                id="mcPerUnit"
                step={0.01}
                {...register('mcPerUnit', { required: true })}
            />
            <Button variant="contained" type="submit" disabled={!isValid}>
                Aggiungi Confezione
            </Button>
        </form>
    );
};

export default CreatePackageForm;
