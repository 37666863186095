import { useDispatch, useSelector } from 'react-redux';
import { RootState, packageActions, PackageState } from 'store';
import { useMount } from './useMount';

export const useGetPackages = () => {
    const dispatch = useDispatch();
    const { packages } = useSelector<RootState>(x => x.package) as PackageState;

    useMount(() => {
        if (!packages?.length) dispatch(packageActions.getPackages() as any);
    });

    return { packages };
};
