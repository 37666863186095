import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';

export interface Transporter {
    id: string;
    firstName: string;
    lastName: string;
    company: string;
    headquarter: string;
    isDeleted: boolean;
    email: string;
    cel: string;
}

export interface TransporterState {
    transporters: Transporter[];
}

const name = 'transporter';
const initialState: TransporterState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getTransporters.fulfilled, (state, action) => {
            state.transporters = action.payload.data;
        });
    }
});

export const transporterActions = { ...slice.actions, ...extraActions };
export const transporterReducer = slice.reducer;

function createInitialState() {
    return {
        transporters: []
    };
}

function createReducers() {
    return {
        setItems,
        addTransporter,
        updateTransporter
    };

    function setItems(state: TransporterState, action: PayloadAction<{ key: keyof TransporterState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addTransporter(state: TransporterState, action: PayloadAction<Transporter>) {
        state.transporters = [...state.transporters, action.payload];
    }

    function updateTransporter(state: TransporterState, action: PayloadAction<Transporter>) {
        const transporters = [...state.transporters];
        const index = transporters.findIndex(transporter => transporter.id === action.payload.id);
        if (index >= 0) transporters.splice(index, 1, action.payload);
        state.transporters = transporters;
    }
}

function createExtraReducers() {
    return {
        getTransporters: getTransportersThunk()
    };
    function getTransportersThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/transporters`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}
