import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';

export interface Client {
    id: string;
    name: string;
    headquarter: string;
    email: string;
    cel: string;
    isDeleted: boolean;
}

export interface ClientState {
    clients: Client[];
}

const name = 'client';
const initialState: ClientState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getClients.fulfilled, (state, action) => {
            state.clients = action.payload.data;
        });
    }
});

export const clientActions = { ...slice.actions, ...extraActions };
export const clientReducer = slice.reducer;

function createInitialState() {
    return {
        clients: []
    };
}

function createReducers() {
    return {
        setItems,
        addClient,
        deleteClient,
        updateClient
    };

    function setItems(state: ClientState, action: PayloadAction<{ key: keyof ClientState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addClient(state: ClientState, action: PayloadAction<Client>) {
        state.clients = [...state.clients, action.payload];
    }
    function deleteClient(state: ClientState, action: PayloadAction<string>) {
        const clients = [...state.clients];
        const index = clients.findIndex(client => client.id === action.payload);
        if (index >= 0) clients[index].isDeleted = true;
        state.clients = clients;
    }

    function updateClient(state: ClientState, action: PayloadAction<Client>) {
        const clients = [...state.clients];
        const index = clients.findIndex(client => client.id === action.payload.id);
        if (index >= 0) clients.splice(index, 1, action.payload);
        state.clients = clients;
    }
}

function createExtraReducers() {
    return {
        getClients: getClientsThunk()
    };
    function getClientsThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/clients`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}
