import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { history } from 'helpers';
import { authActions, AuthState, RootState } from 'store';
import { useDebounce } from 'hook';

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
    const { token, tokenExpiration } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();

    const delay = useMemo(() => {
        if (!tokenExpiration) return 100;
        return tokenExpiration - Date.now();
    }, [tokenExpiration]);

    const logout = useCallback(() => dispatch(authActions.logout()), [dispatch]);
    useDebounce(logout, delay);

    if (!token) {
        return <Navigate to="/login" state={{ from: history.location }} />;
    }

    return <>{children}</>;
};
export default PrivateRoute;
