import classes from './EditObject.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, emailActions } from 'store';

const EditObject = () => {
    const { object } = useSelector((x: RootState) => x.email);
    const dispatch = useDispatch();

    const onChangeHandler = (e: any) => {
        dispatch(emailActions.updateObject(e.target.value));
    };

    return (
        <div className={classes.container}>
            <label htmlFor="profession"></label>
            <input
                className={classes.form__field}
                placeholder="Oggetto Vuoto"
                id="profession"
                value={object}
                onChange={onChangeHandler}
            />
        </div>
    );
};

export default EditObject;
