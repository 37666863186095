import { Fill, Stroke, Style } from 'ol/style';

export const STATUS_TRANSLATIONS = {
    paid: 'Pagato',
    unpaid: 'Non Pagato',
    handled: 'Evaso'
};

export const DEFAULT_STYLE = new Style({
    fill: new Fill({ color: 'rgba(182, 203, 250, 0.2)' }),
    stroke: new Stroke({ width: 1, color: 'rgba(88, 133, 237)' })
});

export const CONFLICT_STYLE = new Style({
    fill: new Fill({ color: 'rgba(252, 45, 82, 0.3)' }),
    stroke: new Stroke({ color: 'rgba(152, 35, 82, 1)', width: 2 }),
    zIndex: 1
});

export const UNAVAILABLE_STYLE = new Style({
    fill: new Fill({ color: 'rgba(0, 0, 0, 0.1)' }),
    stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.8)', width: 1 })
});

export const SELECTED_STYLE = new Style({
    fill: new Fill({ color: 'rgba(52, 235, 82, 0.3)' }),
    stroke: new Stroke({ color: 'rgba(52, 235, 82, 1)', width: 2 }),
    zIndex: 1
});

export const HOVER_STYLE = new Style({
    fill: new Fill({ color: 'rgba(182, 203, 250, 0.6)' }),
    stroke: new Stroke({ width: 1, color: 'rgba(88, 133, 237)' })
});

export const CLICK_STYLE = new Style({
    fill: new Fill({ color: 'rgba(88, 133, 237, 0.4)' }),
    stroke: new Stroke({ width: 1, color: 'rgba(27, 82, 209)' })
});

export const REGION_STYLE = new Style({
    fill: new Fill({ color: 'transparent' }),
    stroke: new Stroke({ width: 2, color: 'black' })
});
